import { MurkhIntro } from "../../components/desktop/MurkhIntro";
import { MurkhAbout } from "../../components/desktop/MurkhAbout";
import { MurkhShop } from "../../components/desktop/MurkhShop";
import { MurkhContact } from "../../components/desktop/MurkhContact";
import { useEffect, useState } from "react";
import { CursorTrail } from "../../components/desktop/CursorTrail";
import { HomeContainer } from "../../styles/pages/desktop/Home";
import { Loader } from "../../components/desktop/Loader";
import { Navbar } from "../../components/desktop/Navbar";

export function Home() {
  const [currentPage, setCurrentPage] = useState("intro");
  const [isLoaderDone, setIsLoaderDone] = useState(false);

  useEffect(() => {
    const animationDuration = 3000; // Change the duration as needed

    // Check if the user has already seen the loader in this session
    if (sessionStorage.getItem("hasSeenLoader")) {
      setIsLoaderDone(true);
    } else {
      const timeout = setTimeout(() => {
        setIsLoaderDone(true);
        sessionStorage.setItem("hasSeenLoader", "true");
      }, animationDuration);

      return () => clearTimeout(timeout);
    }
  }, []);

  const handleSetActivePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <HomeContainer>
      {isLoaderDone ? null : <Loader />}
      <CursorTrail currentPage={currentPage} isLoaderDone={isLoaderDone} />
      <MurkhIntro setActivePage={handleSetActivePage} />
      <Navbar />
      {isLoaderDone ? (
        <>
          <MurkhAbout setActivePage={handleSetActivePage} />
          <MurkhShop setActivePage={handleSetActivePage} />
          <MurkhContact setActivePage={handleSetActivePage} />
        </>
      ) : null}
    </HomeContainer>
  );
}
