import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop.png"

export const CheckoutPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;

`

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50vw;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 20vh;
  margin-bottom: 3vh;
`

export const StepNumber = styled.div`
  width: 5vw;
  height: 5vw;
  font-size: 3vw;
  border-radius: 50%;
  border: 4px solid white;
  background-color: black;
  color: white;
  font-family: Gigranche_regular;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  
  &.active{
    border-color: black;
    background-color: rgb(255, 235, 7);
    color: black;
  }

`

export const ProgressBar = styled.img`
  width: 95%;
  height: 20%;
  position: absolute;
  z-index: 0;
  left: 3%

`

export const ProcessContainer = styled.div `
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

`