import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop.png"
import disclaimer from "../../../assets/img/blue-03.png"

export const ShopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const TileWrap = styled.div`
  width: 68%;
  height: 73%;
  overflow-x: hidden;
  position: relative;
  left: 18.5vw;
  top: 27vh;
  display: grid;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none; 
  scrollbar-width: none;  
`

export const Disclaimer = styled.div`
  /* background-image: url(${disclaimer});
  background-repeat: no-repeat;
  background-size: 16vw 120vh; */
  /* background-position: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 13vw;
  height: 65vh;
  position: absolute;
  right: 1vw;
  top: 27vh;
  border: 1px solid white;
    border-radius: 2vh;
    background-color:rgba(0, 0, 0, 0.6);
    color:white;

`

export const DisclaimerContent = styled.div`
    text-align: center;
    margin: 1vw;
    margin-right: 2vw;
    font-size: 2.40vh;
    color: white;
    font-family: GangsterGrotesk_bold;
    &:hover{
      color: #30D5C8;
    }

`

export const EmptySerieTextContainer = styled.div`
  background : black;
  opacity: 0.8;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-self: center;
  margin: 0;
`

export const EmptySerieText = styled.p`
  color: white;
  font-family: GangsterGrotesk_bold;
  font-size: 3vw;
  text-align: center;
  opacity: 1;
  padding: 2vw;

`