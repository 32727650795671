import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop.png"
import { Link } from "react-router-dom";

export const AcceptedPaymentPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;

`

export const ThanksContainer = styled.div`
  font-family: GangsterGrotesk_bold;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
`

export const ThanksText = styled.p`
    font-family: Gigranche_semi;
    font-size:5.5vw;
    color: black;
    margin:0 0 20px 0;
`

export const NumberText = styled.p`
    font-family: Gigranche_semi;
    font-size:3vw;
    margin:0 0 20px 0;
`

export const ConfirmEmailText = styled.p`
    font-family: Gigranche_semi;
    font-size:3vw;
    margin:0 0 20px 0;
`

export const LinkCartBase = styled(Link)`
    font-size:3vw;
    margin:10px 0;
    border:none;
    padding:7px 30px;
    color:white;
    background:black;
    text-decoration: none;
    cursor: none;
`

export const ContactUs = styled(LinkCartBase)`
    background:#30D5C8;
    color : black;

`

export const GoBack = styled(LinkCartBase)`
    background:#FFDF00;
    color : black;

`