import {
	Arrow,
	ArticleContainer,
	ArticleDescriptionContainer,
	ArticleDetails,
	ArticlePriceContainer,
	ArticlePriceDetails,
	ArticleTitle,
	CartContainer,
	CheckoutContainer,
	EmptyCartText,
	Exit,
	ImageArticle,
	LinkCheckout,
	ListContainer,
	Separator,
	ShopLink,
	Title,
	TotalPrice
} from "../../styles/components/mobile/Cart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from "@fortawesome/free-solid-svg-icons";
import arrow from "../../assets/img/arrow_checkout.png";
import { CartContext } from "./../CartContext";
import {useContext} from "react";

export function Cart({ isOpen, onClose, isInitCart }) {
	const { items, totalCart } = useContext(CartContext);

	const handleClose = () => {
		onClose();
	};

	function scrollToShop() {
		const contactSection = document.getElementById("shop");
		contactSection.scrollIntoView({ behavior: "smooth" });
		onClose();
	}

	return (
		<CartContainer isOpen={isOpen} isInitCart={isInitCart}>
			<Exit onClick={handleClose}>
				<FontAwesomeIcon icon={faX} size="2xl" color="white" />
			</Exit>
			<Title>
				Your cart
			</Title>
			{items.length > 0 ? (
				<ListContainer>
					{items.map((item) => (
						<ArticleContainer key={item.id}>
							<ImageArticle src={item.picture} alt={item.title} />
							<ArticleDescriptionContainer>
								<ArticleTitle>{item.title}</ArticleTitle>
								<ArticleDetails>{item.size}</ArticleDetails>
								<ArticleDetails>{item.type}</ArticleDetails>
							</ArticleDescriptionContainer>
							<ArticlePriceContainer>
								<ArticlePriceDetails>{item.price}€</ArticlePriceDetails>
								<ArticlePriceDetails>{item.quantity}</ArticlePriceDetails>
							</ArticlePriceContainer>
						</ArticleContainer>
					))}
				</ListContainer>
			) : (
				<EmptyCartText>
					Your cart is empty, <ShopLink to={"/shop"}>Go to shop</ShopLink>
				</EmptyCartText>
			)}
			<Separator />
			<TotalPrice>Total : {totalCart()}€</TotalPrice>
			{items.length > 0 && (
                <>
                    <CheckoutContainer>
                        <LinkCheckout to={"/shop/checkout"}>
                            Checkout
                            <Arrow src={arrow} alt={"arrow"} />
                        </LinkCheckout>
                    </CheckoutContainer>
                </>
            )}
		</CartContainer>
	)
}