import styled from "styled-components";
import {Link} from "react-router-dom";

export const TileContainer = styled.div`
  font-family: Gigranche_regular;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

`

export const TileWrap = styled(Link)`
  width:100%;
  padding:0;
  margin:0;
  text-align:center;
  display:flex;
  flex-direction:column;
  cursor: inherit;
  text-decoration: none;

`

export const TileImg = styled.img`
  width:100%;
  margin:0;
  padding:0;
  z-index:2;
  position:relative;

`

export const TileDetails = styled.div`
  background-color:rgba(0, 0, 0, 0.6);
  color:white;
  width:75%;
  text-align:center;
  margin:auto;
  top:-10px;
  position:relative;
  padding:2.5vh 5px;
  border: 1px solid white;
  border-radius: 2vh;
  
`

export const BrushedText = styled.div`
  font-size:3vh;
  font-family: GangsterGrotesk_bold;
  margin:0;
  padding-bottom:2vh;
  color: white;

`

export const ButtonShop = styled(Link)`
  background:black;
  font-size : 2vh;
  padding: 5px 5px;
  font-family: GangsterGrotesk_bold;
  text-align: center;
  border-radius:0.5vh;
  margin-top:10vh;
  border:1px solid #FEF102;
  text-decoration: none;
  color: white;
  cursor: inherit;

`