import {
	ContactContainer,
	ContactInfoContainer,
	Title,
	TitleContainer,
	ContactInfo,
	Link
} from "../../styles/components/mobile/MurkhContact";

export function MurkhContact() {
	return (
		<ContactContainer id={"contact"}>
			<TitleContainer>
				<Title>
					contact
				</Title>
			</TitleContainer>
			<ContactInfoContainer>
				<ContactInfo className={"instagram"}>
					<Link href="https://www.instagram.com/murkhasya/" target="_blank">ig: @murkhasya</Link>
				</ContactInfo>
				<ContactInfo className={"phone"}>
					<Link href="tel:+34644841690" target="_blank">phone +34644841690</Link>
				</ContactInfo>
				<ContactInfo className={"mail"}>
					<Link href="mailto:murkhasya@gmail.com" target="_blank">murkhasya@gmail.com</Link>
				</ContactInfo>
				<ContactInfo className={"adress"}>
					<Link href="https://goo.gl/maps/VtKYhH4wg3ih85SRA" target="_blank">Carrer d'Avinyó 21, local 3, Barcelona 08002</Link>
				</ContactInfo>
			</ContactInfoContainer>
		</ContactContainer>
	)
}