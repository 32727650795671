import styled, {css, keyframes} from "styled-components";
import {Link} from "react-router-dom";
import { device } from "../../Device";

const openNavBarContent = keyframes`
  0% {
    height: 1vh;
    width: 0;
    visibility: visible;
  }
  50% {
    height: 1vh;
    width: 80vw;
    visibility: visible;
  }
  100% {
    height: ${({ Shop }) =>
        Shop ? "80vh" : "50vh"};
    width: 80vw;
    visibility: visible;
  }
`;

const closeNavBarContent = keyframes`
  0% {
    height: ${({ Shop }) =>
        Shop ? "80vh" : "50vh"};
    width: 80vw;
    visibility: visible;
  }
  50% {
    height: 1vh;
    width: 80vw;
    visibility: visible;
  }
  100% {
    height: 1vh;
    width: 0;
    visibility: visible;
  }
`;


export const InstaIconContainer = styled.a`
  position: fixed;
  left: 30px;
  width: 9vw;
  max-width: 60px;
`

export const InstaIcon = styled.img`
    width: 9vw;
    filter: invert();
`

export const NavBarContainer = styled.div`
  position: fixed;
  margin: 2vh 5vh;
  z-index: 1000;
`

export const CartIcon = styled.img`
  position: fixed;
  right: 30px;
  height: 9vw;
  max-height: 60px;
  filter: invert();
`

export const NavBarOpener = styled.img`
  position: fixed;
  height: 12vh;
  bottom: 2vh;
  left: 50%;
  margin-left: -6vh;
  border: 3px solid black;
  z-index: 2;
  border-radius: 50%;
  transition: transform 0.6s ease-in-out;
  ${({ isOpen, isInitNav }) =>
      (isInitNav ? (isOpen &&
          css`
      transform: rotate(360deg);
    `) : "none")}
`

export const NavBarContent = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ Shop }) =>
      Shop ? "center" : ""};
  justify-content: ${({ Shop }) =>
      Shop ? "" : "space-evenly"};
  background-color: black;
  bottom: 9vh;
  left: 40%; 
  transform: translateX(-50%); 
  margin: 0px 10vw;
  width: 80vw;
  text-align: center;
  visibility: hidden;
  font-size: 5vh;
  height: ${({ Shop }) =>
      Shop ? "80vh" : "50vh"};
  border-radius: 2vh;
  overflow: hidden;
  animation-name: ${({ isOpen, isInitNav }) => (isInitNav ? (isOpen ? openNavBarContent : closeNavBarContent) : "none")};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`

export const Section = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Gigranche_semi;
    color: white;
    text-decoration: none;
  
    &.endNav {
      margin-bottom: 5vw;
    }
`

export const ColorVariant = styled.p`
    color: #30D5C8;
`

export const CategoriesTitleSection = styled.div`
    font-family: Gigranche_regular;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 5vh;
    margin-top: 6vh;

    @media ${device.mobileS} {
      font-size: 4vh;
    }

    @media ${device.tablet} {
        font-size: 4vh;
    }
    
`

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 4vh;
  font-family: GangsterGrotesk_regular;
  color: white;


`

export const Categorie = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2vh;
  margin-top: 4vh;

`

export const Separator = styled.div`
  height: 4px;
  width: 80%;
  background: white;
  margin: 4vh 0px;
  border-radius: 50px;
  margin-top: auto;
`

export const SectionShop = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Gigranche_semi;
    color: white;
    text-decoration: none;
    height: 5vh;
    margin-bottom: 3vh;
    
    &.endNav {
      margin-bottom: 10vh;
    }
`
