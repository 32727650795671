import { MurkhContact } from "../../components/mobile/MurkhContact"
import { NavBar } from "../../components/mobile/NavBar"

export function Contact() {

    return (
        <>
            <NavBar Contact={true} />
            <MurkhContact />
        </>
    )
}