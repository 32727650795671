import {AboutContainer, Description, Title, TitleContainer} from "../../styles/components/mobile/MurkhAbout";

export function MurkhAbout() {
	return (
		<AboutContainer id={"about"}>
			<Description>
				The word Murkhaysa is a Sanskrit word which can be translated to 'Things from a fool'. At our core, we believe in the power of artistic expression to shape our world. Founded with a passion for supporting emerging talents, we curate a diverse collection of contemporary artworks that captivate and inspire. Our design studio bridges the realms of art and technology, seamlessly merging creative imagination with engineering expertise. From thought-provoking paintings to functional yet artful designs, we strive to ignite dialogue and push boundaries.
			</Description>
			<TitleContainer>
				<Title>
					about
				</Title>
			</TitleContainer>
		</AboutContainer>
	)
}