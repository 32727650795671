import {Disclaimer, DisclaimerContent, ShopContainer, TileWrap, EmptySerieText, EmptySerieTextContainer} from "../../styles/pages/desktop/Shop";
import {Navbar} from "../../components/desktop/Navbar";
import {CursorTrail} from "../../components/desktop/CursorTrail";
import {ShopCategories} from "../../components/desktop/ShopCategories";
import {ShopTile} from "../../components/desktop/ShopTile";
import Masonry from "react-masonry-css";
import {useEffect, useState} from "react";
import {getAllArtwork, getAllArtworkFromSeries} from "../../services/artworks";

export function Shop() {


	const breakpointColumnsObj = {
		default: 4,
		1386: 3,
		1100: 2,
		480: 1,
	  };
	
	  const [panelData, setpanelData] = useState([]);
	  const [selectedCategory, setSelectedCategory] = useState(null);
	
	  const AllArt = async () => {
		setpanelData(await getAllArtwork());
	  };
	
	  const loadCategoryArtwork = async (categoryId) => {
		const categoryArtwork = await getAllArtworkFromSeries(categoryId);
		setpanelData(categoryArtwork);
	  };
	
	  useEffect(() => {
		if (selectedCategory) {
		  loadCategoryArtwork(selectedCategory);
		} else {
		  AllArt();
		}
	  }, [selectedCategory]);
	
	  let generatePanels = [];
	
	  panelData.forEach((data) => {
		generatePanels.push(<ShopTile key={data.Id} data={data} />);
	  });



	return (
		<ShopContainer>
			<Navbar />
			<ShopCategories onSelectCategory={setSelectedCategory} selectedCategory={selectedCategory}/>
			<CursorTrail currentPage={"shop"} isLoaderDone={true}/>
			<TileWrap>
			{generatePanels.length > 0 ? ( 
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="masonryGrid"
				columnClassName="masonryGridColumn"
			>
				{generatePanels}
			</Masonry>
			) : (
			<EmptySerieTextContainer>
				<EmptySerieText>
					No paintings in this series... Yet :D
				</EmptySerieText>
			</EmptySerieTextContainer>
			)}
			</TileWrap>
			<Disclaimer>
				<DisclaimerContent>The Paintings are sold without the frames. But we can sell the frame as well.</DisclaimerContent>
				<DisclaimerContent>It will be €165. The shipping will depend on the location. :D</DisclaimerContent>
				<DisclaimerContent>Each painting is accompanied by a certificate of authenticity by the artist.</DisclaimerContent>
				<DisclaimerContent>Please check our shipping policy.</DisclaimerContent>
			</Disclaimer>
		</ShopContainer>
	)
}