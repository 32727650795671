import {
    ShippingContainer,
    ErrorMessage,
    InputField,
    SelectField,
    ArrowContainer, ArrowText, Arrow, NavContainer
} from "../../styles/components/mobile/ShippingForm";
import React, {useState, useEffect} from "react";
import countriesData from "../../assets/data/countries.json";
import arrow from "../../assets/img/arrow_checkout.png";
import validator from "validator"
import { GetCookie, SetCookie} from "./../../services/cookies"

export const ShippingForm = ( {handleContinueClick, handleReturnClick, formFields} ) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const [errors, setErrors] = useState({});
	const [activeErrorField, setActiveErrorField] = useState(null);
	const [emailError, setEmailError] = useState(false); // New state for email error

	let cook = GetCookie('userdata') !== undefined ? JSON.parse(GetCookie('userdata')) : undefined;

	const [form, setForm] = useState({
        name: cook !== undefined && cook.name !== undefined ? cook.name : '',
        email: cook !== undefined && cook.email !== undefined ? cook.email : '',
        phone: cook !== undefined && cook.phone !== undefined ? cook.phone : '',
        country: cook !== undefined && cook.country !== undefined ? cook.country : '',
        address: cook !== undefined && cook.address !== undefined ? cook.address : '',
        address2: cook !== undefined && cook.address2 !== undefined ? cook.address2 : '',
        city: cook !== undefined && cook.city !== undefined ? cook.city : '',
        zip: cook !== undefined && cook.zip !== undefined ? cook.zip : '',
        idCard: cook !== undefined && cook.idCard !== undefined ? cook.idCard : '',
        comment: cook !== undefined && cook.comment !== undefined ? cook.comment : '',
    });

	useEffect(() => {
		setForm((prevForm) => ({
			...prevForm,
			email: cook !== undefined && cook.email !== undefined ? cook.email : '',
		}));
	}, [cook]);
	
	const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });

        if (name === 'email') {
            setEmailError(false);
        }
    };

    const areMandatoryFieldsFilled = () => {
        const mandatoryFields = formFields.filter((field) => !field.isOptional);
        return mandatoryFields.every(
            (field) =>
                form[field.name].trim() !== '' &&
                (field.name !== 'email' || emailRegex.test(form[field.name].trim()))
        );
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (!formFields.find((field) => field.name === name)?.isOptional) {
            if (name === 'email') {
                setEmailError(false);
                setErrors({ ...errors, [name]: value.trim() === '' });
                if (value.trim() !== '' && !emailRegex.test(value.trim())) {
                    setEmailError(true);
                }
                setActiveErrorField(name);
            } else {
                setErrors({ ...errors, [name]: value.trim() === '' });
                setActiveErrorField(name);
            }
        }
    };

    const handleContinue = () => {
        // Validate fields
        if (
            form.name !== (undefined || '') &&
            form.email !== (undefined || '') &&
            validator.isEmail(form.email) &&
            form.phone !== (undefined || '') &&
            validator.isMobilePhone(form.phone) &&
            form.country !== (undefined || '') &&
            form.address !== (undefined || '') &&
            form.city !== (undefined || '') &&
            form.zip !== (undefined || '')
        ) {
            // Set cookie with the form data
            SetCookie('userdata', JSON.stringify(form), 60);
            handleContinueClick(); // Continue to the next step
        }
    };

    return (
        <ShippingContainer>
            {formFields.map((field) => (
                <React.Fragment key={field.name}>
                    {field.name === "country" ? (
                        <SelectField
                            name={field.name}
                            value={form[field.name]}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                        >
                            <option value="">Select a country</option>
                            {countriesData.map((country) => (
                                <option key={country.country} value={country.country}>
                                    {country.country}
                                </option>
                            ))}
                        </SelectField>
                    ) : (
                        <InputField
                            type={field.name === "email" ? "email" : "text"}
                            name={field.name}
                            placeholder={`${field.label}${!field.isOptional ? "*" : ""}`}
                            value={form[field.name]}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                        />
                    )}
                    {field.name === "email" &&
                        emailError &&
                        activeErrorField === field.name && (
                            <ErrorMessage>{`${field.label} is not valid`}</ErrorMessage>
                        )}
                    {!field.isOptional &&
                        errors[field.name] &&
                        activeErrorField === field.name && (
                            <ErrorMessage>{`${field.label} is required`}</ErrorMessage>
                        )}
                </React.Fragment>
            ))}
            <NavContainer>
                <ArrowContainer onClick={handleReturnClick}>
                    <ArrowText>
                        Return
                        <Arrow className={"reverse"} src={arrow} alt={"arrow return"}/>
                    </ArrowText>
                </ArrowContainer>
                {areMandatoryFieldsFilled() && !emailError && (
                    <ArrowContainer onClick={handleContinue}>
                        <ArrowText>
                            Continue
                            <Arrow className={"xl"} src={arrow} alt={"arrow return"}/>
                        </ArrowText>
                    </ArrowContainer>
                )}
            </NavContainer>
        </ShippingContainer>
    )
}