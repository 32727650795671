import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop_mobile.png"

export const ShopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TileWrap = styled.div`
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 70%;
  height: 95%;
  overflow-x: hidden;
  position: relative;
  left: 5vw;
  top: 5vh;
`

export const EmptySerieTextContainer = styled.div`
  background : black;
  opacity: 0.8;
  width: fit-content;
  height: fit-content;
  display: flex;
  position: absolute;
  top: 40%;
  margin: 0;
`

export const EmptySerieText = styled.p`
  color: white;
  font-family: GangsterGrotesk_bold;
  font-size: 5vw;
  text-align: center;
  opacity: 1;
  padding: 2vw;

`