import {
	CartIcon, CategoriesTitleSection, ColorVariant,
	InstaIcon,
	InstaIconContainer,
	NavBarContainer, NavBarContent,
	NavBarOpener, Section, SectionShop, Separator
} from "../../styles/components/mobile/NavBar";
import insta from "./../../assets/img/insta_logo.png"
import cart from "./../../assets/img/cart_logo.png"
import logo from "./../../assets/img/icon_navbar.png"
import {useState} from "react";
import {Cart} from "./Cart";
import { ShopCategories } from "./ShopCategories";

export function NavBar( {Shop = false, About=false, Contact=false, onSelectCategory, selectedCategory} ) {
	const [isOpen, setIsOpen] = useState(false);
	const [isCartOpen, setCartOpen] = useState(false);
	const [isInitCart, setIsInitCart] = useState(false);
	const [isInitNav, setIsInitNav] = useState(false);

	const handleCartClick = () => {
		setCartOpen(!isCartOpen);
		if(!isInitCart) {
			setIsInitCart(true);
		}
	};

	const handleCartClose = () => {
		setCartOpen(false);
	};

	const toggleNavBar = () => {
		setIsOpen((prevIsOpen) => !prevIsOpen);
		if(!isInitNav) {
			setIsInitNav(true);
		}
	};

	return (
		<NavBarContainer>
			<InstaIconContainer href="https://www.instagram.com/murkhasya/" target="_blank">
				<InstaIcon src={insta} alt={'insta logo'} />
			</InstaIconContainer>
			<CartIcon src={cart} alt={'cart logo'} onClick={handleCartClick} />
			<NavBarOpener src={logo} alt={'murkhasya logo'} onClick={toggleNavBar} isOpen={isOpen} isInitNav={isInitNav} />
			<NavBarContent isOpen={isOpen} Shop={Shop} isInitNav={isInitNav}>
				{Shop ? (
					<>
						<CategoriesTitleSection>Categ<ColorVariant>o</ColorVariant>ries</CategoriesTitleSection>
						<ShopCategories toggleNavBar={toggleNavBar} onSelectCategory={onSelectCategory} selectedCategory={selectedCategory}/>
						<Separator />
						<SectionShop to={"/"}>
							H<ColorVariant>o</ColorVariant>me
						</SectionShop>
						<SectionShop to={"/contact"} className={"endNav"}>
							C<ColorVariant>o</ColorVariant>ntact
						</SectionShop>
					</>
					)
					: (
					<>
						{About ? (
							<Section to={"/"}>
								H<ColorVariant>o</ColorVariant>me
							</Section>
						) : (
							<Section to={"/about"}>
								Ab<ColorVariant>o</ColorVariant>ut
							</Section>
						)}
						{Contact ? (
							<Section to={"/"}>
								H<ColorVariant>o</ColorVariant>me
							</Section>
						) : (
							<Section to={"/contact"}>
								C<ColorVariant>o</ColorVariant>ntact
							</Section>
						)}
						<Section className={'endNav'} to={"/shop"}>
							Sh<ColorVariant>o</ColorVariant>p
						</Section>
					</>
				)}

			</NavBarContent>
			<Cart isOpen={isCartOpen} onClose={handleCartClose} isInitCart={isInitCart}/>
		</NavBarContainer>
	);
}