import styled, {css} from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_contact_mobile.jpg"
import titleimg from "./../../../assets/img/title_background_contact.png"
import contactimg from "./../../../assets/img/background_info_contact.png"

export const ContactContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 13vh;
  margin-top: 20vh;
  background-image: url(${titleimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

export const Title = styled.span`
  font-family: Gigranche_regular;
  color: black;
  font-size: 15vw;
  margin-bottom: 1vw;
  margin-right: 20vw;
`

export const ContactInfoContainer = styled.div`
  width: 100vw;
  height: 60vh;
  background-image: url(${contactimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 1.5vw;
`

export const ContactInfo = styled.div`
  font-family: Hypercrack;
  font-size: 3.8vh;
  position: absolute;

  ${props =>
    props.className === 'instagram' &&
    css`
      transform: translate(6%,200%) rotate(-6deg);
    `}

  ${props =>
    props.className === 'phone' &&
    css`
      transform: translate(10%,400%) rotate(-5deg);
    `}

  ${props =>
    props.className === 'mail' &&
    css`
      transform: translate(10%,510%) rotate(-3deg);
    `}

  ${props =>
    props.className === 'adress' &&
    css`
        width: 80vw;
        transform: translate(15%,350%) rotate(-3deg);
        text-align: center;
    `}

`

export const Link = styled.a`
  color: white;
  text-decoration: none;
`