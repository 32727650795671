import {
	AddCart,
	AlreadyAdded,
	ArtInfo,
	ArtInfoContainer, ArtTitle,
	DetailsContainer,
	DetailsContentContainer,
	Painting,
	PaintingContainer, Return
} from "../../styles/pages/desktop/Details";
import {Navbar} from "../../components/desktop/Navbar";
import {CursorTrail} from "../../components/desktop/CursorTrail";
import { useParams, useNavigate } from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import {getDetailsFromProductWithId} from "../../services/artworks";
import { CartContext } from './../../components/CartContext';

export function Details() {
	const { id } = useParams();
	const [productDetails, setProductDetails] = useState(null);
	const cart = useContext(CartContext);

	const navigate = useNavigate();

	useEffect(() => {
		// Fetch product details based on the id
		async function fetchProductDetails() {
			const details = await getDetailsFromProductWithId(id);
			setProductDetails(details);
		}

		fetchProductDetails();
	}, [id]);

	const isItemInCart =
    productDetails &&
    cart.items.some((item) => item.id === productDetails[0].Id);

	return (
		<DetailsContainer>
			<Navbar />
			<CursorTrail currentPage={"shop"} isLoaderDone={true} />
			<DetailsContentContainer>
				{
					!productDetails ? (
						<></>
					) : (
						<>
							<PaintingContainer>
								<Painting zoomFactor={2} src={productDetails[0].Picture} alt={"a quality painting"} />
							</PaintingContainer>
							<ArtInfoContainer>
								<ArtTitle>{productDetails[0].Title}</ArtTitle>
								<ArtInfo>Size: {productDetails[0].Size}</ArtInfo>
								<ArtInfo>Medium: {productDetails[0].Type}</ArtInfo>
								<ArtInfo>Price: {productDetails[0].Price}€</ArtInfo>
								{isItemInCart ? (
									<AlreadyAdded>Already added to cart</AlreadyAdded>
								) : (
								
									<AddCart onClick={() => cart.addCart(productDetails[0])}>
									Add to cart
									</AddCart>
								)}
								{/* <Return onClick={() => navigate(-1)}>Go back</Return> */}
							</ArtInfoContainer>
						</>
					)
				}
			</DetailsContentContainer>
		</DetailsContainer>
	)
}