import { MurkhAbout } from "../../components/mobile/MurkhAbout"
import { NavBar } from "../../components/mobile/NavBar"

export function About() {

    return (
        <>
            <NavBar About={true} />
            <MurkhAbout />
        </>
    )
}