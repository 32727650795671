import styled from "styled-components";
import backimg from "../../../assets/img/blue-01.png"

export const CategoriesContainer = styled.div`
    /* background-image: url(${backimg}); //TODO: Bora edit white line
    background-repeat: no-repeat;
    background-size: cover; */
    /* width: 17vw;
    height: 40vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 2vh; */
    position: absolute;
    max-width: 15vw;
    left: 1.5vw;
    top: 27vh;
    border: 1px solid white;
    border-radius: 2vh;
    background-color:rgba(0, 0, 0, 0.6);

`

export const Categories = styled.a`
    font-family: GangsterGrotesk_bold;
    font-size: 3vh;
    color: black;
    /* text-transform: uppercase; */
    text-decoration: none;
    color:white;
    &.isActive{
      color: #30D5C8;
    }
    &:hover{
      color: #30D5C8;
    }
  margin: 1.5vh;
  text-align: center;

`