import { 
    AcceptedPaymentPageContainer, 
    ThanksContainer,
    ThanksText,
    NumberText,
    ConfirmEmailText,
    ContactUs,
    GoBack
} from "../../styles/pages/mobile/PaymentAccepted"
import { NavBar } from "../../components/mobile/NavBar"
import { useContext, useEffect } from "react"
import { CartContext } from "../../components/CartContext"
import { GetCookie } from "../../services/cookies"

export function PaymentAccepted() {
    const cart = useContext(CartContext)
  
    useEffect(() => {
        cart.emptyCart();
    }, [])
    

    let dataset = {
        "Number": GetCookie("orderID")
    }

    return (
        <AcceptedPaymentPageContainer>
            <NavBar />
            <ThanksContainer>
              <ThanksText>Thanks for your purchase!</ThanksText>
              <NumberText>Your order number is #{dataset.Number}</NumberText>
              <ConfirmEmailText>you will receive an email with the confirmation</ConfirmEmailText>
              <ContactUs><a href="mailto:murkhasya@gmail.com" style={{textDecoration:"none", cursor:"none", color:"black"}}>Contact Us</a></ContactUs>
              <GoBack to="/shop">Continue shopping</GoBack>
            </ThanksContainer>
        </AcceptedPaymentPageContainer>
    )
}