import {ShopContainer, TitleContainer, Title, Character, TempLink, TempText} from "../../styles/components/mobile/MurkhShop";
import char from "./../../assets/img/mister-shop.png"
import { Link } from "react-router-dom";
import { PolaroidImage } from "../../styles/components/desktop/Polaroid";
import pol1 from "./../../assets/polaroids/1.png"
import pol2 from "./../../assets/polaroids/2.png"
import pol3 from "./../../assets/polaroids/3.png"
import pol4 from "./../../assets/polaroids/4.png"
import pol5 from "./../../assets/polaroids/5.png"

export function MurkhShop() {
	return (
		<ShopContainer id={"shop"} style={{position:'relative'}}>
			<TitleContainer>
				<Title>
					shop
				</Title>
			</TitleContainer>
			<Character src={char} alt={"geometric character"}/>
			<Link to={'/shop'}><PolaroidImage src={pol1} height={'30%'}  left={'5%'} top={'10%'} style={{ transform:'rotate(10deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol2} height={'30%'} left={'25%'} top={'2%'}style={{ transform:'rotate(-5deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol3} height={'30%'} left={'50%'} bottom={'15%'}style={{  transform:'rotate(10deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol4} height={'30%'} left={'50%'} top={'10%'}style={{transform:'rotate(-10deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol5} height={'30%'} left={'45%'} bottom={'5%'} style={{ transform:'rotate(2deg)'}}/></Link>
			
			{/* <TempText>
				Coming soon! We are preparing this section with all our love. In the meantime, you can reach out to us on our
				<TempLink href="https://www.instagram.com/murkhasya/" target="_blank"> instagram: @murkhasya</TempLink> or through <TempLink href="mailto:murkhasya@gmail.com" target="_blank">email: murkhasya@gmail.com</TempLink>
			</TempText> */}
		</ShopContainer>
	)
}