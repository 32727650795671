import styled, {keyframes} from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_home.png"
import titleimg from "./../../../assets/img/title_background_home.png"

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64vw;
  height: 16vh;
  background-image: url(${titleimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const Title = styled.span`
  font-family: Gigranche_regular;
  color: black;
  font-size: 6.5vw;
  margin-bottom: 1vw;
`

export const Description = styled.p`
      font-family: GangsterGrotesk_regular;
      color: black;
      width: 50vw;
      font-size: 2vw;
      margin: 0;
      text-align: center;
`

const drop = keyframes`
  from {
    transform: none;
    scale: 1;
    opacity: 1;
  }
  to {
    transform: translateY(100%) rotate(20deg);
    scale:1.1;
    opacity: 0;
  }
`;

export const TitleAnimated = styled.span`
  font-family: Gigranche_regular;
  color: transparent;
  font-size: 6.5vw;
  position: absolute;
  margin-bottom: 1vw;
  -webkit-text-stroke: 1px black;
  &:hover{
    animation: ${drop} 1s ease-in-out forwards;
  }
`