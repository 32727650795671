import {
	ShopContainer,
	Title,
	TitleAnimated,
	TitleContainer,
	Character,
	TempText, TempLink
} from "../../styles/components/desktop/MurkhShop";
import char from "./../../assets/img/mister-shop.png"
import { PolaroidImage } from "../../styles/components/desktop/Polaroid";
import pol1 from "./../../assets/polaroids/1.png"
import pol2 from "./../../assets/polaroids/2.png"
import pol3 from "./../../assets/polaroids/3.png"
import pol4 from "./../../assets/polaroids/4.png"
import pol5 from "./../../assets/polaroids/5.png"
import {Link} from "react-router-dom";

export function MurkhShop( {setActivePage} ) {
	const handleMouseEnter = () => {
		setActivePage("shop");
	};

	return (
		<ShopContainer onMouseEnter={handleMouseEnter} id={"shop"} style={{marginTop:'-5vh', position:'relative'}}>
			<TitleContainer>
				<Title>
					shop
				</Title>
				<TitleAnimated>
					shop
				</TitleAnimated>
			</TitleContainer>
			<Character src={char} alt={"geometric character"}/>
			<Link to={'/shop'}><PolaroidImage src={pol1} height={'50%'}  left={'10%'} top={'10%'} style={{ transform:'rotate(10deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol2} height={'50%'} left={'30%'} top={'2%'}style={{ transform:'rotate(-5deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol3} height={'50%'} left={'80%'} bottom={'20%'}style={{  transform:'rotate(10deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol4} height={'50%'} left={'70%'} top={'10%'}style={{transform:'rotate(-10deg)'}}/></Link>
			<Link to={'/shop'}><PolaroidImage src={pol5} height={'50%'} left={'5%'} bottom={'10%'} style={{ transform:'rotate(2deg)'}}/></Link>
			{/* <TempText>ming soon! We are preparing this section with all our love. In the meantime, you can reach out to us on our
				<TempLink href="https://www.instagram.com/murkhasya/" target="_blank"> instagram: @murkhasya</TempLink> or through <TempLink href="mailto:murkhasya@gmail.com" target="_blank">email: murkhasya@gmail.com</TempLink>
			</TempText> */}
		</ShopContainer>
	)
}