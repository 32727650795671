import {
	CartBarContainer,
	CartText,
	HoverText,
	Logo, LogoLink,
	MovingContainer,
	NavBarContainer,
	StretchLetter,
} from "../../styles/components/desktop/Navbar";
import logo from "./../../assets/img/Logo.png";
import {useState} from "react";
import { Cart } from "./Cart";

export function Navbar() {
	const [isCartOpen, setCartOpen] = useState(false);
	const [isInitCart, setIsInitCart] = useState(false);

	const handleCartClick = () => {
		setCartOpen(!isCartOpen);
		if(!isInitCart) {
			setIsInitCart(true);
		}

	};

	const handleCartClose = () => {
		setCartOpen(false);
	};

	return (
		<>
			<NavBarContainer>
				<LogoLink to={"/"}><Logo src={logo} alt={"logo"} /></LogoLink>
				<HoverText to={"/about"} style={{marginLeft:'-2vw'}}>
					AB<StretchLetter className="stretch-letter" />UT
				</HoverText>
				<HoverText to={"/shop"}>
					SH<StretchLetter className="stretch-letter" />P
				</HoverText>
				{/* <HoverText to={"/exhibitions"} style={{marginRight:'2vw'}}>
					EXHIBITI<StretchLetter className="stretch-letter" />NS
				</HoverText> */}
				<HoverText className={"endNav"} to={"/contact"}>
					C<StretchLetter className="stretch-letter" />NTACT
				</HoverText>
				
			</NavBarContainer>
			<CartBarContainer onClick={handleCartClick}>
				<MovingContainer>
					<CartText>- cart - cart - cart - cart - cart - cart - cart&nbsp;</CartText>
					<CartText>- cart - cart - cart - cart - cart - cart - cart&nbsp;</CartText>
				</MovingContainer>
			</CartBarContainer>
			<Cart isOpen={isCartOpen} onClose={handleCartClose} isInitCart={isInitCart} />
		</>
	);
}
