import {ShopContainer, TileWrap, EmptySerieTextContainer, EmptySerieText} from "../../styles/pages/mobile/Shop";
import {NavBar} from "../../components/mobile/NavBar";
import Masonry from "react-masonry-css";
import {ShopTile} from "../../components/desktop/ShopTile";
import { getAllArtwork, getAllArtworkFromSeries } from "../../services/artworks";
import { useState, useEffect } from "react";

export function Shop() {
	const breakpointColumnsObj = {
		default: 4,
		1386: 3,
		1100: 2,
		480 : 1
	};

	const [panelData, setpanelData] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
  
	const AllArt = async () => {
	  setpanelData(await getAllArtwork());
	};
  
	const loadCategoryArtwork = async (categoryId) => {
	  const categoryArtwork = await getAllArtworkFromSeries(categoryId);
	  setpanelData(categoryArtwork);
	};
  
	useEffect(() => {
	  if (selectedCategory) {
		loadCategoryArtwork(selectedCategory);
	  } else {
		AllArt();
	  }
	}, [selectedCategory]);
  
	let generatePanels = [];
  
	panelData.forEach((data) => {
	  generatePanels.push(<ShopTile key={data.Id} data={data} />);
	});

	return (
		<ShopContainer>
			<NavBar Shop={true} onSelectCategory={setSelectedCategory} selectedCategory={selectedCategory}/>
			{generatePanels.length > 0 ? ( 
			<TileWrap>
				<Masonry
					breakpointCols={breakpointColumnsObj}
					className="masonryGrid"
					columnClassName="masonryGridColumn"
				>
					{generatePanels}
				</Masonry>
			</TileWrap>
			) : (
			<EmptySerieTextContainer>
				<EmptySerieText>
					No paintings in this series... Yet :D
				</EmptySerieText>
			</EmptySerieTextContainer>
			)}
		</ShopContainer>
	)
}