import {NavBar} from "../../components/mobile/NavBar";
import {
	ShopContainer,
	DetailsContentContainer,
	PaintingContainer,
	Painting,
	ArtInfoContainer,
	AddCart,
	ArtInfo,
	ArtTitle,
	Return,
	AlreadyAdded
} from "../../styles/pages/mobile/Details";
import { CartContext } from "../../components/CartContext";
import { useParams, useNavigate } from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import { getDetailsFromProductWithId } from "../../services/artworks";


export function Details() {
	const { id } = useParams();
	const [productDetails, setProductDetails] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const cart = useContext(CartContext);

	const navigate = useNavigate();

	useEffect(() => {
		// Fetch product details based on the id
		async function fetchProductDetails() {
			const details = await getDetailsFromProductWithId(id);
			setProductDetails(details);
		}

		fetchProductDetails();
	}, [id]);

	const isItemInCart =
    productDetails &&
    cart.items.some((item) => item.id === productDetails[0].Id);


	return (
		<ShopContainer>
			<NavBar Shop={true} onSelectCategory={setSelectedCategory} selectedCategory={selectedCategory}/>
			<DetailsContentContainer>
			{
					!productDetails ? (
						<></>
					) : (
						<>
							<PaintingContainer>
								<Painting zoomFactor={2} src={productDetails[0].Picture} alt={"a quality painting"} />
							</PaintingContainer>
							<ArtInfoContainer>
								<ArtTitle>{productDetails[0].Title}</ArtTitle>
								<ArtInfo>Size: {productDetails[0].Size}</ArtInfo>
								<ArtInfo>Medium: {productDetails[0].Type}</ArtInfo>
								<ArtInfo>Price: {productDetails[0].Price} €</ArtInfo>
								<div style={{display:'flex', gap:'5vw'}}>
								{isItemInCart ? (
									<AlreadyAdded>Already added to cart</AlreadyAdded>
								) : (
									<AddCart onClick={() => cart.addCart(productDetails[0])}>
									Add to cart
									</AddCart>
								)}
								<Return onClick={() => navigate(-1)}>Go back</Return>
								</div>
							</ArtInfoContainer>
						</>
					)
				}
			</DetailsContentContainer>
		</ShopContainer>
	)
}