import {
	Arrow,
	ArticleContainer,
	ArticleDescriptionContainer,
	ArticleDetails,
	ArticlePriceContainer,
	ArticlePriceDetails,
	ArticleTitle, CheckoutContainer, Continue,
	DeletePainting,
	ImageArticle,
	ListContainer, Separator, TotalPrice, VerificationContainer,
	EmptyCartText,
	ShopLink
} from "../../styles/components/desktop/VerificationCheckout";
import trash from "../../assets/img/delete_painting.png";
import arrow from "../../assets/img/arrow_checkout.png";
import { useContext } from "react";
import { CartContext } from "../CartContext";

export function VerificationCheckout( {handleContinueClick} ) {
	const cart = useContext(CartContext);

  const handleDeleteClick = (itemId) => {
    cart.deleteFromCart(itemId);
  };

  return (
    <VerificationContainer>
      {cart.items.length > 0 ? (
        <ListContainer>
          {cart.items.map((item) => (
            <ArticleContainer key={item.id}>
              <ImageArticle src={item.picture} alt={item.title} />
              <ArticleDescriptionContainer>
                <ArticleTitle>{item.title}</ArticleTitle>
                <ArticleDetails>{item.size}</ArticleDetails>
                <ArticleDetails>{item.type}</ArticleDetails>
              </ArticleDescriptionContainer>
              <ArticlePriceContainer>
                <ArticlePriceDetails>{item.price}€</ArticlePriceDetails>
                <ArticlePriceDetails>{item.quantity}</ArticlePriceDetails>
                <DeletePainting
                  src={trash}
                  alt={"Delete button"}
                  onClick={() => handleDeleteClick(item.id)}
                />
              </ArticlePriceContainer>
            </ArticleContainer>
          ))}
        </ListContainer>
      ) : (
        <EmptyCartText>
			Your cart is empty, <ShopLink to={"/shop"}>Go to shop</ShopLink>
		</EmptyCartText>
      )}
      <Separator />
      <TotalPrice>Total : {cart.totalCart()}€</TotalPrice>
      {cart.items.length > 0 && (
        <>
          <CheckoutContainer onClick={handleContinueClick}>
            <Continue>
              Continue
              <Arrow src={arrow} alt={"arrow"} />
            </Continue>
          </CheckoutContainer>
        </>
      )}
    </VerificationContainer>
  );
}