import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_about_mobile.png"
import titleimg from "./../../../assets/img/title_background_about.png"

export const AboutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const Description = styled.p`
      font-family: GangsterGrotesk_regular;
      margin-top: 13vw;
      color: black;
      width: 75vw;
      font-size: 4vw;
      text-align: justify;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70vw;
  height: 13vh;
  background-image: url(${titleimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

export const Title = styled.span`
  font-family: Gigranche_regular;
  color: black;
  font-size: 15vw;
  margin-bottom: 1vw;
`
