import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop_mobile.png"
import Magnifier from "react-magnifier";

export const ShopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const DetailsContentContainer = styled.div `
  text-align: center;
  position: relative;
  top : 4%;

`

export const PaintingContainer = styled.div`
  margin-top: 2vh;
  height: 45vh;
  width: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
`

export const Painting = styled(Magnifier)`
  max-width: 65vw;

`

export const ArtInfoContainer = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

`

export const ArtTitle = styled.div`
  font-family: Gigranche_semi;
  font-size: 4vh;
  margin: 2vh 0;
`

export const ArtInfo = styled.p`
  font-family: Gigranche_regular;
  font-size: 2.5vh;
  margin: 0.2vh 0px;
`

export const AddCart = styled.div`
  background: #FFDF00;
  font-size: 2vh;
  margin: 0.9vh 0;
  font-family: Gigranche_regular;
  border: none;
  padding: 7px 30px;
  width: fit-content;
  color: black;

`

export const Return = styled.div`
  // background: #FFDF00;
  background: #30D5C8;
  font-size: 2vh;
  margin: 0.9vh 0;
  font-family: Gigranche_regular;
  border: none;
  padding: 7px 30px;
  width: fit-content;
  color: black;

`

export const AlreadyAdded = styled.p`
  color: #30D5C8;
  font-family: GangsterGrotesk_bold;
  font-size: 3vw;
  margin: 0.9vh 0;

`