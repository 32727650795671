import styled, {keyframes} from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop.png"
import titleimg from "./../../../assets/img/title_background_shop.png"

export const ShopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const drop = keyframes`
  from {
    transform: none;
    scale: 1;
    opacity: 1;
  }
  to {
    transform: translateY(-100%) rotate(-20deg);
    scale:1.1;
    opacity: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40vw;
  height: 16vh;
  background-image: url(${titleimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  right: 15%;
  margin-top: 6.5vw;
  
`

export const Title = styled.span`
  font-family: Gigranche_regular;
  color: black;
  font-size: 7vw;
  margin-left: 6vw;
  
`

export const TitleAnimated = styled.span`
  font-family: Gigranche_regular;
  color: transparent;
  font-size: 7vw;
  position: absolute;
  -webkit-text-stroke: 1px black;
  margin-left: 6vw;
  &:hover{
    animation: ${drop} 1s ease-in-out forwards;
  }
`

export const Character = styled.img `
  width: 18%;
  position: absolute;
  transform: translate(-80%,50%);
`

export const TempText = styled.div`
  font-family: GangsterGrotesk_regular;
  color: black;
  font-size: 5vh;
  width: 50vw;
  transform: translate(40%, 100%);

`
export const TempLink = styled.a `
    text-decoration: none;
    color: white;
    cursor: inherit;
    font-family: GangsterGrotesk_bold;
`