import {
	CheckoutPageContainer,
	ProcessContainer,
	ProgressBar,
	ProgressBarContainer,
	StepNumber,
} from "../../styles/pages/desktop/Checkout";
import {Navbar} from "../../components/desktop/Navbar";
import {CursorTrail} from "../../components/desktop/CursorTrail";
import bar from "./../../assets/img/checkout_progress.png"
import {useState} from "react";
import {VerificationCheckout} from "../../components/desktop/VerificationCheckout";
import {ShippingForm} from "../../components/desktop/ShippingForm";
import {DeliveryAndDetails} from "../../components/desktop/DeliveryAndDetails";

export function Checkout() {
	const [isStep, setIsStep] = useState(1);

	const formFields = [
		{ name: "name", label: "Name" },
		{ name: "email", label: "Email" },
		{ name: "phone", label: "Phone" },
		{ name: "country", label: "Country" },
		{ name: "address", label: "Address" },
		{ name: "address2", label: "Address 2", isOptional: true },
		{ name: "city", label: "City" },
		{ name: "zip", label: "ZIP" },
		{ name: "idCard", label: "ID Card" },
		{ name: "comment", label: "Comment", isOptional: true },
	];

	const handleContinueClick = () => {
		setIsStep(isStep + 1);
	}

	const handleReturnClick = () => {
		setIsStep(isStep - 1);
	}


	return (
		<CheckoutPageContainer>
			<Navbar />
			<CursorTrail currentPage={"shop"} isLoaderDone={true} />
			<ProcessContainer>
				<ProgressBarContainer>
					<ProgressBar src={bar} alt={"progess bar"}/>
					<StepNumber className={isStep === 1 ? "active" : ""}>1</StepNumber>
					<StepNumber className={isStep === 2 ? "active" : ""}>2</StepNumber>
					<StepNumber className={isStep === 3 ? "active" : ""}>3</StepNumber>
				</ProgressBarContainer>
				{isStep === 1 ? <VerificationCheckout handleContinueClick={handleContinueClick}/> : ""}
				{isStep === 2 ? <ShippingForm handleContinueClick={handleContinueClick} handleReturnClick={handleReturnClick} formFields={formFields} /> : ""}
				{isStep === 3 ? <DeliveryAndDetails handleReturnClick={handleReturnClick} formFields={formFields}/> : ""}
			</ProcessContainer>
		</CheckoutPageContainer>
	)
}