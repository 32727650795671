import {Description, MainContainer, Title, TitleContainer, TitleAnimated} from "../../styles/components/desktop/MurkhIntro";

export function MurkhIntro( {setActivePage} ) {
	const handleMouseEnter = () => {
		setActivePage("intro");
	};

	return (
		<MainContainer onMouseEnter={handleMouseEnter}>
			<TitleContainer>
				<Title>
					Murkhasya
				</Title>
				<TitleAnimated>
					Murkhasya
				</TitleAnimated>
			</TitleContainer>
			<Description>
				Multidimensional art space exhibiting contemporary artworks by emerging talents. Our inhouse design studio fuses concepts of graphic design seamlessly with technology.
			</Description>
		</MainContainer>
	)
}