import {
	BrushedText,
	ButtonShop,
	TileContainer,
	TileDetails,
	TileImg,
	TileWrap
} from "../../styles/components/desktop/ShopTile";

export function ShopTile( {data} ) {
	return (
		<TileContainer>
			<TileWrap to={`/shop/details/${data.Id}`}>
				<TileImg src={data.Picture} alt={data.Title}/>
				<TileDetails>
					<BrushedText>{data.Title} - € {data.Price}</BrushedText>
					{/* <BrushedText>€ {data.Price}</BrushedText> */}
					<ButtonShop to={`/shop/details/${data.Id}`}>Shop now</ButtonShop>
				</TileDetails>
			</TileWrap>
		</TileContainer>
	)
}