import { MurkhAbout } from "../../components/desktop/MurkhAbout"
import { Navbar } from "../../components/desktop/Navbar"
import { CursorTrail } from "../../components/desktop/CursorTrail"

export function About() {

    return (
        <>
            <Navbar />
            <CursorTrail currentPage={"about"} isLoaderDone={true} />
            <MurkhAbout page={true}/>
        </>
    )
}