import {
    CheckoutPageContainer,
    ProcessContainer,
    ProgressBar,
    ProgressBarContainer,
    StepNumber
} from "../../styles/pages/mobile/Checkout";
import {NavBar} from "../../components/mobile/NavBar";
import {useState} from "react";
import bar from "../../assets/img/checkout_progress.png";
import {VerificationCheckout} from "../../components/mobile/VerificationCheckout";
import {ShippingForm} from "../../components/mobile/ShippingForm";
import {DeliveryAndDetails} from "../../components/mobile/DeliveryAndDetails";

export const Checkout = () => {

    const [isStep, setIsStep] = useState(1);

    const formFields = [
        { name: "name", label: "Name" },
        { name: "email", label: "Email" },
        { name: "phone", label: "Phone" },
        { name: "country", label: "Country" },
        { name: "address", label: "Address" },
        { name: "address2", label: "Address 2", isOptional: true },
        { name: "city", label: "City" },
        { name: "zip", label: "ZIP" },
        { name: "idCard", label: "ID Card" },
        { name: "comment", label: "Comment", isOptional: true },
    ];

    const handleContinueClick = () => {
        setIsStep(isStep + 1);
    }

    const handleReturnClick = () => {
        setIsStep(isStep - 1);
    }

    return (
        <CheckoutPageContainer>
            <NavBar />
            <ProcessContainer>
                <ProgressBarContainer>
                    <ProgressBar src={bar} alt={"progess bar"}/>
                    <StepNumber className={isStep === 1 ? "active" : ""}>1</StepNumber>
                    <StepNumber className={isStep === 2 ? "active" : ""}>2</StepNumber>
                    <StepNumber className={isStep === 3 ? "active" : ""}>3</StepNumber>
                </ProgressBarContainer>
                {isStep === 1 ? <VerificationCheckout handleContinueClick={handleContinueClick}/> : ""}
                {isStep === 2 ? <ShippingForm handleReturnClick={handleReturnClick} handleContinueClick={handleContinueClick} formFields={formFields}/> : ""}
                {isStep === 3 ? <DeliveryAndDetails handleReturnClick={handleReturnClick} formFields={formFields} /> : ""}
            </ProcessContainer>
        </CheckoutPageContainer>
    )
}