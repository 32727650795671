import axios from "axios";

export const createCustomer = async (user) => {
    try { 
        const response = await axios.post('customers/new', {
          "name" : user.name,
          "email" : user.email,
          "contact": user.phone,
          "address": user.address,
          "city": user.city,
          "country": user.country,
          "postal": user.zip
        });
        if (response.status === 201) {
          return response.data.Customer.id
        }
      } catch (error) {
        console.log(error)
      }
}

const randStr = (length) => {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
    // Pick characers randomly
    let str = '';
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    
    return str;
    
};

export const createOrder = async (price, shipping, total, notes, customerId, stripeId) => {
    let idOrder = randStr(5);
    
    try {
        const response = await axios.post('orders/new', {
            idOrder,
            price,
            shipping,
            total,
            status:false,
            notes,
            customerId,
            stripeId
        });
        
        if (response.status === 201) {
            // console.log(response)
            return [response.data.order.id, response.data.order.idOrder]
        }

    }catch(e){
        console.log(e);
    }
    
}

export const addItemsToOrder = async (orderID, dataset) => {    
    try{
      for(let i = 0; i < dataset.length; i++){
        const response = await axios.post('productsOrdered/new', {
          ordersId: orderID,
          productId: dataset[i].id,
          quantity: dataset[i].quantity,
          price: dataset[i].price,
          totalPrice : dataset[i].quantity*dataset[i].price,
      });
     }
    }catch(err){
      console.log(err)
    }
  }