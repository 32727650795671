import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop_mobile.png"
import titleimg from "./../../../assets/img/title_background_shop.png"

export const ShopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 13vh;
  background-image: url(${titleimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

export const Title = styled.span`
  font-family: Gigranche_regular;
  color: black;
  font-size: 15vw;
  margin-bottom: 1vw;
  margin-right: 15vw;
`

export const Character = styled.img `
  width: 30%;
  position: absolute;
  transform: translate(-60%,67%);
`

export const TempText = styled.div`
  font-family: GangsterGrotesk_regular;
  color: black;
  font-size: 4vw;
  width: 50vw;
  transform: translate(40%, 80%);
  position: absolute;

`
export const TempLink = styled.a `
    text-decoration: none;
    color: white;
    cursor: inherit;
    font-family: GangsterGrotesk_bold;
`