import styled from "styled-components";
import { device } from "../../Device";

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 4vh;
  font-family: GangsterGrotesk_regular;
  color: white;


`

export const Categorie = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5vh;
  margin-top: 2vh;
  margin-bottom:3vh;
  &.isActive{
      color: #30D5C8;
    }

    @media ${device.mobileS} {
      font-size: 3vh;
    }

    @media ${device.tablet} {
        font-size: 4vh;
    }

`