import {
	ContactContainer, ContactInfo,
	ContactInfoContainer, Link,
	Title,
	TitleAnimated,
	TitleContainer
} from "../../styles/components/desktop/MurkhContact";

export function MurkhContact( {setActivePage, page=false} ) {
	const handleMouseEnter = () => {
		setActivePage("contact");
	};

	return (
		<ContactContainer onMouseEnter={handleMouseEnter} id={"contact"}>
			<TitleContainer className={page ? "page" : ""}>
				<Title>
					contact
				</Title>
				<TitleAnimated>
					contact
				</TitleAnimated>
			</TitleContainer>
			<ContactInfoContainer>
				<ContactInfo className={"instagram"}>
					<Link className="black" href="https://www.instagram.com/murkhasya/" target="_blank">ig: @murkhasya</Link>
				</ContactInfo>
				<ContactInfo className={"phone"}>
					<Link className="black" href="tel:+34644841690" target="_blank">phone +34644841690</Link>
				</ContactInfo>
				<ContactInfo className={"mail"}>
					<Link className="black" href="mailto:murkhasya@gmail.com" target="_blank">murkhasya@gmail.com</Link>
				</ContactInfo>
				<ContactInfo className={"adress"}>
					<Link className="black" href="https://goo.gl/maps/VtKYhH4wg3ih85SRA" target="_blank">Carrer d'Avinyó 21, local 3, Barcelona 08002</Link>
				</ContactInfo>
			</ContactInfoContainer>
		</ContactContainer>
	)
}