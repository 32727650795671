import styled from "styled-components";
import { Link } from "react-router-dom";

export const VerificationContainer = styled.div`
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.6);
    height: 55vh;
    padding: 15px clamp(1px, 7vw, 100px);

`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 23vh;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const ArticleContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 2vh;
`

export const ImageArticle = styled.img`
  width: 30%;
  align-self: center;
  
`

export const ArticleDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-left: 1.5vw;
    font-family: Gigranche_regular;
`

export const ArticleTitle = styled.p`
    font-size: 5vw;
    color: white;
    margin: 2vh 0 1vh 0;
`

export const ArticlePrice = styled.p`
    font-size: 6vw;
    color: white;
    margin: 0;

`

export const ArticleDeleteContainer = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DeletePainting = styled.img`
    width: 50%;
    height: auto;

`

export const Separator = styled.div`
  height: 5px;
  width: 100%;
  background: white;
  margin: 2vh 0px;
  border-radius: 50px;

`

export const TotalPrice = styled.div`
  font-family: Gigranche_regular;
  font-size: 6.5vw;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const CheckoutContainer = styled.div`
  width: 100%;
  font-size: 6vw;
  font-family: Gigranche_regular;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -30%;
`
export const Continue = styled.a`
    text-decoration: none;
    color: white;
`

export const Arrow = styled.img`
  width: 50%;
  position: absolute;
  transform: translate(-85%, -50%);

`

export const EmptyCartText = styled.div`
  font-family: Gigranche_regular;
  font-size: 5vh;
  color: white;
  width: 90%;
  margin-top: 5vh;

`

export const ShopLink = styled(Link)`
    text-decoration: none;
    color: #30D5C8;

`