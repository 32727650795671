import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home as MobileHome } from "./pages/mobile/Home";
import { Home as DesktopHome } from "./pages/desktop/Home";
import {Shop as DesktopShop} from "./pages/desktop/Shop";
import { Shop as MobileShop } from "./pages/mobile/Shop";
import {Details as DesktopDetails} from "./pages/desktop/Details";
import {Details as MobileDetails} from "./pages/mobile/Details";
import {Checkout as DesktopCheckout} from "./pages/desktop/Checkout";
import {Checkout as MobileCheckout} from "./pages/mobile/Checkout";
import { PaymentAccepted as DesktopPaymentAccepted } from "./pages/desktop/PaymentAccepted";
import { PaymentAccepted as MobilePaymentAccepted } from "./pages/mobile/PaymentAccepted";
import { About as DesktopAbout } from "./pages/desktop/About";
import { About as MobileAbout } from "./pages/mobile/About";
import { Contact as DesktopContact } from "./pages/desktop/Contact";
import { Contact as MobileContact } from "./pages/mobile/Contact";

import { Exhibition as DesktopExhibition } from "./pages/desktop/Exhibitions";
import { Exhibition as MobileExhibition } from "./pages/mobile/Exhibitions";

import CartProvider from "./components/CartContext";

function App() {
    const [isMobile, setIsMobile] = useState(
        () => window.matchMedia("(max-width: 475px)").matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 475px)");

        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        mediaQuery.addEventListener("change", handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange);
        };
    }, []);

    return (
        <CartProvider>
            <div className="App">
                <Router>
                    <Routes>
                        <Route path="/" element={isMobile ? <MobileHome /> : <DesktopHome />} />
                        <Route path="/shop" element={isMobile ? <MobileShop /> : <DesktopShop />} />
                        {/* TODO: Add the categories for the shops */}
                        <Route path="/shop/details/:id" element={isMobile ? <MobileDetails /> : <DesktopDetails />} />
                        <Route path="/shop/checkout" element={isMobile ? <MobileCheckout /> : <DesktopCheckout />} />
                        <Route path="/shop/checkout/accepted" element={isMobile ? <MobilePaymentAccepted /> : <DesktopPaymentAccepted/>} />
                        <Route path="/about" element={isMobile ? <MobileAbout /> : <DesktopAbout/>} />
                        <Route path="/contact" element={isMobile ? <MobileContact /> : <DesktopContact/>} />
                        {/* <Route path="/exhibitions" element={isMobile ? <MobileExhibition /> : <DesktopExhibition />} /> */}
                    </Routes>
                </Router>
            </div>
        </CartProvider>
    );
}

export default App;
