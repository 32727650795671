import {
	AboutContainer,
	CompositionContainer,
	Description,
	Title, TitleAnimated,
	TitleContainer
} from "../../styles/components/desktop/MurkhAbout";

export function MurkhAbout( {setActivePage, page=false} ) {
	const handleMouseEnter = () => {
		setActivePage("about");
	};

	return (
		<AboutContainer onMouseEnter={handleMouseEnter} id={"about"}>
			<CompositionContainer className={page ? "page" : ""}>
				<Description>
					The word Murkhaysa is a Sanskrit word which can be translated to 'Things from a fool'. At our core, we believe in the power of artistic expression to shape our world. Founded with a passion for supporting emerging talents, we curate a diverse collection of contemporary artworks that captivate and inspire. Our design studio bridges the realms of art and technology, seamlessly merging creative imagination with engineering expertise. From thought-provoking paintings to functional yet artful designs, we strive to ignite dialogue and push boundaries.
				</Description>
				<TitleContainer>
					<Title>
						about
					</Title>
					<TitleAnimated>
						about
					</TitleAnimated>
				</TitleContainer>
			</CompositionContainer>
		</AboutContainer>
	)
}