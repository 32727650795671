import {
	MainContainer,
	Title,
	TitleContainer,
	Description,
} from "../../styles/components/mobile/MurkhIntro";

export function MurkhIntro() {
	return (
		<MainContainer>
			<TitleContainer>
				<Title>
					Murkhasya
				</Title>
			</TitleContainer>
			<Description>
				Multidimensional art space exhibiting contemporary artworks by emerging talents. Our inhouse design studio fuses concepts of graphic design seamlessly with technology.
			</Description>
		</MainContainer>
	)
}