import {
    ArticleContainer,
    ListContainer,
    VerificationContainer,
    ImageArticle,
    ArticleDescriptionContainer,
    ArticleDeleteContainer,
    ArticleTitle,
    ArticlePrice,
    DeletePainting,
    Separator,
    TotalPrice,
    Arrow,
    CheckoutContainer,
    Continue,
    EmptyCartText,
    ShopLink
} from "../../styles/components/mobile/VerificationCheckout";
import trash from "./../../assets/img/delete_painting.png"
import arrow from "../../assets/img/arrow_checkout.png";
import { useContext } from "react";
import { CartContext } from "../CartContext";

export function VerificationCheckout( {handleContinueClick} ) {
    const cart = useContext(CartContext);

    const handleDeleteClick = (itemId) => {
      cart.deleteFromCart(itemId);
    };


    return (
        <VerificationContainer>
            {cart.items.length > 0 ? (
        <ListContainer>
          {cart.items.map((item) => (
            <ArticleContainer key={item.id}>
                <ImageArticle src={item.picture} alt={item.title}/>
                <ArticleDescriptionContainer>
                    <ArticleTitle>
                        {item.title}
                    </ArticleTitle>
                    <ArticlePrice>
                        {item.price}€
                    </ArticlePrice>
                </ArticleDescriptionContainer>
                <ArticleDeleteContainer>
                <DeletePainting
                src={trash}
                alt={"Delete button"}
                onClick={() => handleDeleteClick(item.id)}
                />
                </ArticleDeleteContainer>
            </ArticleContainer>
          ))}
        </ListContainer>
      ) : (
        <EmptyCartText>
			Your cart is empty, <ShopLink to={"/shop"}>Go to shop</ShopLink>
		</EmptyCartText>
      )}
            <Separator />
            <TotalPrice>Total : {cart.totalCart()}€</TotalPrice>
            {cart.items.length > 0 && (
                <>
                <CheckoutContainer onClick={handleContinueClick}>
                    <Continue>
                    Continue
                    <Arrow src={arrow} alt={"arrow"} />
                    </Continue>
                </CheckoutContainer>
                </>
            )}
        </VerificationContainer>
    )
}