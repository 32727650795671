import styled from "styled-components";
export const ShippingContainer = styled.div`
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.6);
    height: 55vh;
    max-height: 55vh;
    padding: 15px clamp(1px, 7vw, 100px);
    overflow-y: auto;

`

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 3vw;
  cursor: inherit;
  font-family: GangsterGrotesk_regular;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  font-family: GangsterGrotesk_regular;
`;

export const SelectField = styled.select`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 3vw;
  cursor: inherit;
  font-family: GangsterGrotesk_regular;
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
`

export const ArrowContainer = styled.div`
  width: 50%;
  font-size: 6vw;
  font-family: Gigranche_regular;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -30%;
`
export const ArrowText = styled.a`
    text-decoration: none;
    color: white;
`

export const Arrow = styled.img`
  width: 70%;
  position: absolute;
  transform: translate(-85%, -50%);
  
  &.reverse {
    transform: rotate(180deg) translate(100%, 50%);
  }
  
  &.xl {
    width: 95%;
    height: 70%;
  }

`