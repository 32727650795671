import { useEffect } from "react";
import { createContext, useState } from "react";
import {GetCookie, SetCookie} from "../services/cookies";


export const CartContext = createContext(
    {
        items: [],
        addCart: () => {},
        deleteFromCart: () => {},
        getQuantity : () => {},
        rmOneFromCart: () => {},
        totalCart: () => {},
        emptyCart: () => {}
    }
)

export const CartProvider = ({children}) => {

    const  [cartProducts, setCartProducts] = useState(() => GetCookie("cart") !== undefined ? JSON.parse(GetCookie("cart")) : []);

    useEffect(() => {
        SetCookie("cart", JSON.stringify(cartProducts), 7)
    }, [cartProducts])


    function getQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity
        if (quantity === undefined){
            return 0;
        }
        return quantity;
    }

    function addCart(product) {
        const { Id, Title, Size, Type, Picture, Price } = product;

        const existingProduct = cartProducts.find(product => product.id === Id);

        if (!existingProduct) {
            setCartProducts([
                ...cartProducts,
                {
                    id: Id,
                    quantity: 1,
                    title: Title,
                    size: Size,
                    type: Type,
                    picture: Picture,
                    price: Price,
                }
            ]);
        } else {
            setCartProducts(
                cartProducts.map(
                    product => product.id === Id
                        ? { ...product, quantity: product.quantity + 1 }
                        : product
                )
            );
        }
    }

    function deleteFromCart (id) {
        setCartProducts(
            cartProducts =>
                cartProducts.filter(currentProduct => {
                    return currentProduct.id !== id;
                })
        )
    }

    function rmOneFromCart(id) {
        const quantity = getQuantity(id);

        if(quantity === 1){
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    product => product.id === id
                        ? {...product, quantity: product.quantity-1}
                        : product
                )
            )
        }
    }

    function totalCart() {
        let total = 0;
        cartProducts.forEach((cartItem) => {
            total += (cartItem.price * cartItem.quantity);
        });
        return total;
    }

    function emptyCart(id) {
        setCartProducts([])
    }


    const contextValue = {
        items: cartProducts,
        addCart,
        deleteFromCart,
        rmOneFromCart,
        getQuantity,
        totalCart,
        emptyCart
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;