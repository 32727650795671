import Cookie from "js-cookie"

export const SetCookie = (CookieName, Value, Expires) => {
    Cookie.set(CookieName, Value, {
        expires:Expires,
        secure:true, //TODO: turn it back to true after certficate
        sameSite:'none', //TODO: can be turn back to none after having the certificate
        path:'/'
    })
}

export const GetCookie = (CookieName) => {
    return (
        Cookie.get(CookieName)
    )
}

export const RemoveCookie = (CookieName) => {
    return (
        Cookie.remove(CookieName)
    )
}