import styled, {css, keyframes} from "styled-components";

const fadeOutAnimation = keyframes`
  from {
    z-index: 9999;
    opacity: 1;
    visibility: visible;
  }
  to {
    z-index: -9999;
    opacity: 0;
    visibility: hidden;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  animation: ${({ isVisible }) =>
    isVisible ? css`${fadeOutAnimation} 1s ease-in-out forwards` : "none"};
`;

export const Video = styled.video`
  width: 50%;
  height: auto;
`;