import { Link } from "react-router-dom";
import styled from "styled-components";
export const VerificationContainer = styled.div`
    width: 70vw;
    background-color: rgba(0, 0, 0, 0.6);
    height: 58vh;
    padding: 15px clamp(1px, 10vw, 100px);

`

export const Separator = styled.div`
  height: 5px;
  width: 90%;
  background: white;
  margin: 4vh 0px;
  border-radius: 50px;

`
export const ListContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 35vh;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const ArticleContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 2vh;
`

export const ImageArticle = styled.img`
  width: 19%;
  align-self: center;
  
`

export const ArticleDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-left: 1.5vw;
`

export const ArticleTitle = styled.p`
    font-family: Gigranche_regular;
    font-size: 3vw;
    color: white;
    margin: 2vh 0 0 0;
`

export const ArticleDetails = styled.p`
    font-family: GangsterGrotesk_regular;
    font-size: 2vw;
    color: white;
    margin: 0;

`

export const ArticlePriceContainer = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: end;
`

export const ArticlePriceDetails = styled.p`
 font-family: Gigranche_regular;
  font-size: 2.5vw;
  color: white;
  margin: 0;

`

export const DeletePainting = styled.img`
    width: 2vw;
    height: auto;

`

export const TotalPrice = styled.div`
  font-family: Gigranche_regular;
  font-size: 2.5vw;
  color: white;
  width: 90%;
  display: flex;
  justify-content: flex-end;
`

export const CheckoutContainer = styled.div`
  width: 90%;
  font-size: 2.5vw;
  font-family: Gigranche_regular;
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  margin-bottom: 2vh;
`
export const Continue = styled.a`
    text-decoration: none;
    color: white;
`

export const Arrow = styled.img`
  width: 16%;
  position: absolute;
  transform: translate(-85%, -50%);

`

export const EmptyCartText = styled.div`
  font-family: Gigranche_regular;
  font-size: 3vw;
  color: white;
  width: 90%;
  margin-top: 5vh;

`

export const ShopLink = styled(Link)`
    text-decoration: none;
    color: white;
    cursor: none;
  
    &:hover{
      color: #30D5C8;
    }

`