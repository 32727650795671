import {MurkhIntro} from "../../components/mobile/MurkhIntro";
import {NavBar} from "../../components/mobile/NavBar";
import {MurkhAbout} from "../../components/mobile/MurkhAbout";
import {MurkhShop} from "../../components/mobile/MurkhShop";
import {Loader} from "../../components/mobile/Loader";
import {useEffect, useState} from "react";
import {MurkhContact} from "../../components/mobile/MurkhContact";

export function Home() {
	const [isLoaderDone, setIsLoaderDone] = useState(false);
	const [currentPage, setCurrentPage] = useState("intro");
	

	useEffect(() => {
	  const animationDuration = 3000; // Change the duration as needed
  
	  // Check if the user has already seen the loader in this session
	  if (sessionStorage.getItem("hasSeenLoader")) {
		setIsLoaderDone(true);
	  } else {
		const timeout = setTimeout(() => {
		  setIsLoaderDone(true);
		  sessionStorage.setItem("hasSeenLoader", "true");
		}, animationDuration);
  
		return () => clearTimeout(timeout);
	  }
	}, []);
  
	const handleSetActivePage = (page) => {
	  setCurrentPage(page);
	};
	return (
		<>
			{isLoaderDone ? null : <Loader />}
			<NavBar />
			<MurkhIntro />
			{isLoaderDone ? (
				<>
					<MurkhAbout />
					<MurkhShop />
					<MurkhContact />
				</>
				) : null }
		</>
	)
}