import {Categorie, CategoriesContainer} from "../../styles/components/mobile/ShopCategories";
import {getAllSeries} from "../../services/artworks";
import {useEffect, useState} from "react";

export function ShopCategories( {onSelectCategory, selectedCategory, toggleNavBar} ) {
	const [categoriesList, setCategoriesList] = useState([]);

  const AllSeries = async () => {
    setCategoriesList(await getAllSeries());
  };

  useEffect(() => {
    AllSeries();
  }, []);

  const handleClickCategories = (Id) => {
    toggleNavBar();
    onSelectCategory(Id);
  }


  return (
    <CategoriesContainer>
      {categoriesList.map((data) => {
        const categoryName = data.Name;
        return (
          <Categorie key={data.Id} onClick={() => handleClickCategories(data.Id)} className={data.Id === selectedCategory ? "isActive" : ""}>
             # {categoryName}
          </Categorie>
        );
      })}
    </CategoriesContainer>
  );
}