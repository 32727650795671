import styled from "styled-components";
export const MainContainer = styled.div`
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.6);
    height: 55vh;
    max-height: 55vh;
    padding: 15px clamp(1px, 7vw, 100px);
    overflow-y: auto;

`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 23vh;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const ArticleContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 2vh;
`

export const ImageArticle = styled.img`
  width: 30%;
  align-self: center;
  
`

export const ArticleDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-left: 1.5vw;
    font-family: Gigranche_regular;
`

export const ArticleTitle = styled.p`
    font-size: 5vw;
    color: white;
    margin: 2vh 0 1vh 0;
`

export const ArticlePrice = styled.p`
    font-size: 6vw;
    color: white;
    margin: 0;

`

export const DeliveryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

`

export const DeliveryTitle = styled.p`
  text-align: right;
  color: white;
  font-size: 5vw;
  margin-top: 2vh;
  margin-bottom: 0px;
  font-family: Gigranche_regular;

`

export const OptionContainer = styled.div`
  padding: 10px 0px;
  margin-bottom: 1.5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 1vh;
  justify-content: flex-end;
`

export const LabelDelivery = styled.label `
  font-family: GangsterGrotesk_regular;
  font-size: 2.7vh;
  padding-left: 7vh;
  padding-top: 1.2vh;
  color: white;
  cursor: inherit;
  margin-right: 2vw;
    
`

export const StyledCheckbox = styled.div`
  width: 5.2vh;
  height: 5.2vh;
  position: relative;
  margin: 0;
  background: black;
  border-radius: 100%;
  transform: scale(0.90);
`;

export const CheckboxLabel = styled.label`
  display: inline-block;
  width: 4.2vh;
  height: 4.2vh;
  position: absolute;
  top: 0.45vh;
  left: 0.5vh;
  cursor: inherit;
  background: #fef102;
  border-radius: 100%;

  &::after {
    content: "";
    width: 2.3vh;
    height: 1.2vh;
    position: absolute;
    top: 1vh;
    left: 0.7vh;
    border: 4px solid black;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
  }
`;

export const HiddenRadio = styled.input.attrs({ type: "radio" })`
  visibility: hidden;
`;

export const StyledCheckboxInput = styled(HiddenRadio)`
  &:checked + ${CheckboxLabel}:after {
    opacity: 1;
  }
`;

export const Separator = styled.div`
  height: 5px;
  width: 100%;
  background: white;
  margin: 4vh 0 0 0px;
  border-radius: 50px;

`

export const TotalPrice = styled.div`
  font-family: Gigranche_regular;
  font-size: 6.5vw;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1vh;
`

export const DropdownContainer = styled.div`
  margin-top: 10px;
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border-top : 2px solid white;
  width: 100%;
  height: 5vh;
  
`;

export const DropButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 2vw;
  color: white;

`

export const DropTitle = styled.h2`
  font-family: Gigranche_regular;
  color: white;

`

export const DropdownContent = styled.div`
  background-color: transparent;
  padding: 10px;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: ${({ isOpen }) => (isOpen ? "column" : "none")};
`;

export const Detail = styled.p`
  font-family: GangsterGrotesk_regular;
  color: white;
  font-size: 3vw;
  margin: 0.3vh;

`