import {
    DeliveryListContainer,
    MainContainer,
    ArticleContainer,
    ArticleDescriptionContainer,
    ArticleDetails,
    ArticlePriceContainer,
    ArticlePriceDetails,
    ArticleTitle,
    ImageArticle,
    ListContainer,
    DeliveryContainer,
    DeliveryTitle,
    Separator,
    OptionContainer,
    LabelDelivery,
    DetailsPayContainer,
    DetailsContainer,
    Detail, PayContainer, Return, TotalPrice, StyledCheckbox, StyledCheckboxInput, CheckboxLabel
} from "../../styles/components/desktop/DeliveryAndDetails";
import arrow from "../../assets/img/arrow_form.png";
import axios from 'axios'
import {createCustomer, createOrder, addItemsToOrder} from "../../services/payment";
import {useState, useEffect, useContext} from "react";
import { CartContext } from "../CartContext";
import StripeCheckout from 'react-stripe-checkout'
import { Navigate } from 'react-router-dom'
import { GetCookie, SetCookie } from "../../services/cookies";
import sendEmailConfimation from "../../services/sendEmailConfimation"

export function DeliveryAndDetails( {handleReturnClick, formFields} ) {

    const [shippingChecked, setShippingChecked] = useState(false);
    const [noShippingChecked, setNoShippingChecked] = useState(false);
    const [Validate, setValidate] = useState(false)

    const stripeKey = process.env.REACT_APP_STRIPE_KEY;
  
    const [userFormData, setUserFormData] = useState({}); // State to hold user data
    const cart = useContext(CartContext); // Use the CartContext
  
    useEffect(() => {
      // Retrieve user data from the cookie
      const userData =
        GetCookie("userdata") !== undefined ? JSON.parse(GetCookie("userdata")) : {}; 
      setUserFormData(userData);
    }, []);
  
    const handleShippingCheckboxChange = (e) => {
      setShippingChecked(e.target.checked);
      setNoShippingChecked(false); // Uncheck the other checkbox
    };
  
    const handleNoShippingCheckboxChange = (e) => {
      setNoShippingChecked(e.target.checked);
      setShippingChecked(false); // Uncheck the other checkbox
    };

    const user = JSON.parse(GetCookie('userdata'));
    const ShippingFee = shippingChecked ? 35 : 0
    const totalWithShipping = cart.totalCart() + ShippingFee;

    const apiLink = process.env.REACT_APP_BACKEND_SERVER_MURKHASYA;

    const payNow = async token => {

        try {
          const link = `${apiLink}/orders/payment/murkhasya`
          const response = await axios.post(link, {
            amount: Math.floor(totalWithShipping * 100),
            token,
          });
          
          if (response.status === 200) {
              const token = response.data.checkId;
              try{
                const customerID = await createCustomer(user);
                const orderID = await createOrder(cart.totalCart(), parseFloat(ShippingFee), totalWithShipping, user.comment, customerID, token);
                
                await addItemsToOrder(orderID[0], cart.items);
  
                SetCookie("orderID", orderID[1], 1);
  
                await sendEmailConfimation(orderID[1], cart.items, user, ShippingFee, cart.totalCart(), totalWithShipping);
                
                setValidate(true);
              }catch(e){
                console.log(e)
              }
          }
        } catch (error) {
          console.log(error);
        }
      };

    return (
        <MainContainer>
            <Return src={arrow} alt={"return arrow"} onClick={handleReturnClick} />
            <DeliveryListContainer>
                <ListContainer>
                {cart.items.map((item) => (
                    <ArticleContainer key={item.id}>
                    <ImageArticle src={item.picture} alt={"example test 1"} />
                    <ArticleDescriptionContainer>
                        <ArticleTitle>{item.title}</ArticleTitle>
                        <ArticleDetails>{item.size}</ArticleDetails>
                        <ArticleDetails>{item.type}</ArticleDetails>
                    </ArticleDescriptionContainer>
                    <ArticlePriceContainer>
                        <ArticlePriceDetails>{item.price}€</ArticlePriceDetails>
                        <ArticlePriceDetails>{item.quantity}</ArticlePriceDetails>
                    </ArticlePriceContainer>
                    </ArticleContainer>
                ))}
                </ListContainer>
                <DeliveryContainer>
                    <DeliveryTitle>
                        DELIVERY OPTIONS:
                    </DeliveryTitle>
                    <OptionContainer>
                        <LabelDelivery>
                            Shipping : 35€
                        </LabelDelivery>
                        <StyledCheckbox>
                            <StyledCheckboxInput
                                type="checkbox"
                                id="shipping"
                                checked={shippingChecked}
                                onChange={handleShippingCheckboxChange}
                            />
                            <CheckboxLabel htmlFor="shipping" />
                        </StyledCheckbox>
                    </OptionContainer>
                    <OptionContainer>
                        <LabelDelivery>
                            Collect in the gallery : 0€
                        </LabelDelivery>
                        <StyledCheckbox>
                            <StyledCheckboxInput
                                type="checkbox"
                                id="no-shipping"
                                checked={noShippingChecked}
                                onChange={handleNoShippingCheckboxChange}
                            />
                            <CheckboxLabel htmlFor="no-shipping" />
                        </StyledCheckbox>
                    </OptionContainer>
                </DeliveryContainer>
            </DeliveryListContainer>
            <Separator />
            <DetailsPayContainer>
                <DetailsContainer>
                {formFields.map((field) => (
                        <Detail key={field.name}>
                            {field.label} : {userFormData[field.name]}
                        </Detail>
                    ))}
                </DetailsContainer>
                <PayContainer>
                    <TotalPrice>
                        Total : {totalWithShipping}€
                    </TotalPrice>
                    {(shippingChecked || noShippingChecked) ? 
                    <div style={{textAlign:"right"}}>
                        <StripeCheckout  
                        id
                        stripeKey={stripeKey}
                        label="Pay Now"
                        name="Pay With Credit Card"
                        currency="EUR"
                        bitcoin
                        amount={totalWithShipping*100}
                        description={`Purchase on Galeria Maxo for ${totalWithShipping}€`}
                        token={payNow}
                        style={{scale:"1.3", marginRight:"20px", cursor:"none"}}
                        />
                        <br/>
                    </div>
                    : ""}
                </PayContainer>
            </DetailsPayContainer>
            {Validate && <Navigate to="accepted" replace/>}
        </MainContainer>
    )
}