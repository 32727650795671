import { styled } from "styled-components";

export const PolaroidImage = styled.img`
height: ${props => props.height};
width: 'auto';
position: absolute;
top: ${props => props.top};
left: ${props => props.left};
bottom: ${props => props.bottom};
transition: all 200ms ease-in-out;

    &:hover{
        height:55%;
        z-index:2;
    }
`
