import styled, {css, keyframes} from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_contact.png"
import titleimg from "./../../../assets/img/title_background_contact.png"
import contactimg from "./../../../assets/img/background_info_contact.png"

export const ContactContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const drop = keyframes`
  from {
    transform: none;
    scale: 1;
    opacity: 1;
  }
  to {
    transform: translateY(100%) rotate(20deg);
    scale:1.1;
    opacity: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40vw;
  height: 16vh;
  background-image: url(${titleimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 10vw;
  margin-bottom: 5vw;

  &.page {
    margin-left: 50vw;
    margin-bottom: 0;
    transform: rotate(10deg);
  }
  
`

export const Title = styled.span`
  font-family: Gigranche_regular;
  color: black;
  font-size: 6vw;
  margin-left: 2vw;
  
`

export const TitleAnimated = styled.span`
  font-family: Gigranche_regular;
  color: transparent;
  font-size: 6vw;
  position: absolute;
  -webkit-text-stroke: 1px black;
  margin-left: 2vw;
  &:hover{
    animation: ${drop} 1s ease-in-out forwards;
  }
`

export const ContactInfoContainer = styled.div`
  width: 100vw;
  height: 60vh;
  background-image: url(${contactimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 1.5vw;
  margin-left: 4vw;
`

export const ContactInfo = styled.div`
  font-family: Hypercrack;
  font-size: 3vw;

  ${props =>
          props.className === 'instagram' &&
          css`
            margin-bottom: 2vw;
            margin-top: -3.5vw;
            margin-left: 6vw;
            transform: rotate(-8deg);
            
    `}

  ${props =>
          props.className === 'phone' &&
          css`
            margin-left: 20vw;
            transform: rotate(-6deg);
            margin-bottom: 3vw;
    `}

  ${props =>
          props.className === 'mail' &&
          css`
            margin-left: 27vw;
            transform: rotate(-4deg);
            margin-bottom: 4vw;
    `}

  ${props =>
          props.className === 'adress' &&
          css`
            margin-left: 27vw;
            width: 57vw;
            text-align: center;
    `}

`

export const Link = styled.a`
  color: white;
  text-decoration: none;
  cursor: inherit;
`