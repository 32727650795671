import styled, {keyframes} from "styled-components";
import { Link } from "react-router-dom";

const openCart = keyframes`
  from {
    opacity: 0.95;
    transform: translate(35vw, 0);
  }
  to {
    opacity: 0.95;
    transform: translate(0, 0);
  }
`;

const closeCart = keyframes`
  from {
    opacity: 0.95;
    transform: translate(0, 0);
  }
  to {
    opacity: 0.95;
    transform: translate(35vw, 0);
  }
`;

export const CartContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 28vw;
  height: 100vh;
  background: black;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translate(35vw, 0);
  animation-name: ${({ isOpen, isInitCart }) => (isInitCart ? (isOpen ? openCart : closeCart) : "none")};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

export const Title = styled.div`
  margin-top: 3vw;
  font-family: Gigranche_regular;
  font-size: 2.5vw;
  color: white;
`;

export const Exit = styled.div`
  position: absolute;
  left: 1vw;
  top: 1vw;
`;

export const ListContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 60vh;
`

export const ArticleContainer = styled.div`
    width: 100%;
    display: flex;
`

export const ImageArticle = styled.img`
  width: 19%;
  align-self: center;
  
`

export const ArticleDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
`

export const ArticleTitle = styled.p`
    font-family: Gigranche_regular;
    font-size: 1.8vw;
    color: white;
    margin: 2vh 0 0 0;
`

export const ArticleDetails = styled.p`
    font-family: GangsterGrotesk_regular;
    font-size: 1vw;
    color: white;
    margin: 0;

`

export const ArticlePriceContainer = styled.div`
  width: 16%;
`

export const ArticlePriceDetails = styled.p`
 font-family: Gigranche_regular;
  font-size: 1.5vw;
  color: white;

`

export const Separator = styled.div`
  height: 5px;
  width: 90%;
  background: white;
  margin: 4vh 0px;
  border-radius: 50px;

`

export const TotalPrice = styled.div`
  font-family: Gigranche_regular;
  font-size: 2vw;
  color: white;
  width: 90%;
  display: flex;
  justify-content: flex-end;
`

export const CheckoutContainer = styled.div`
  width: 90%;
  font-size: 2vw;
  font-family: Gigranche_regular;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 2vh;
  cursor: none;
`
export const LinkCheckout = styled(Link)`
    text-decoration: none;
    color: white;
    cursor: none;
`

export const Arrow = styled.img`
  width: 50%;
  position: absolute;
  transform: translate(-85%, -50%);
  cursor: none;

`

export const EmptyCartText = styled.div`
  font-family: Gigranche_regular;
  font-size: 2vw;
  color: white;
  width: 90%;
  margin-top: 5vh;

`

export const ShopLink = styled(Link)`
    text-decoration: none;
    color: white;
    cursor: none;
  
    &:hover{
      color: #30D5C8;
    }

`