import {LoaderContainer, Video} from "../../styles/components/mobile/Loader";
import videoFile from "./../../assets/video/animation_no_name.mp4"
import {useEffect, useState, useRef} from "react";

export function Loader() {
	const [isVisible, setIsVisible] = useState(true);
	const videoRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			setIsVisible(false)
		}, 3000); // Change the duration as needed
		videoRef.current.playbackRate = 2;
	}, []);

	return (
		<LoaderContainer isVisible={!isVisible}>
			<Video ref={videoRef} autoPlay muted>
				<source src={videoFile} type="video/mp4" />
			</Video>
		</LoaderContainer>
	)
}