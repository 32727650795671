import styled from "styled-components";

export const ShippingContainer = styled.div`
  width: 70vw;
  background-color: rgba(0, 0, 0, 0.6);
  height: 58vh;
  padding: 15px clamp(1px, 10vw, 100px);
  overflow-y: auto;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.65vw;
  cursor: inherit;
  font-family: GangsterGrotesk_regular;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  font-family: GangsterGrotesk_regular;
`;

export const SelectField = styled.select`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.65vw;
  cursor: inherit;
  font-family: GangsterGrotesk_regular;
`;


export const Continue = styled.img`
  width: 3%;
  position: absolute;
  top: 36vh;
  right: 10vw;

`

export const Return = styled.img`
  width: 3%;
  position: absolute;
  top: 36vh;
  left: 10vw;
  transform: rotate(180deg);

`

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4vh;
`

export const ArrowContainer = styled.div`
  width: 50%;
  font-size: 2.5vw;
  font-family: Gigranche_regular;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -30%;
`
export const ArrowText = styled.a`
    text-decoration: none;
    color: white;
`

export const Arrow = styled.img`
  width: 33%;
  position: absolute;
  transform: translate(-87%, -50%);
  
  &.reverse {
    transform: rotate(180deg) translate(100%, 50%);
  }
  
  &.xl {
    width: 45%;;
  }

`
