import styled from "styled-components";
import backimg from "./../../../assets/img/Murkhasya_shop.png"
import Magnifier from "react-magnifier";

export const DetailsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

`

export const DetailsContentContainer = styled.div`
    width: 80%;
    display: flex;
    position: relative;
    top: 28vh;
    left: 15vw;
`

export const PaintingContainer = styled.div`
    width: 33%;
    cursor: none;
    position: relative;
    z-index: 2;
  
`

export const Painting = styled(Magnifier)`
    max-width: 100%;
`

export const ArtInfoContainer = styled.div`
  /* width: 50%; */
  min-width: 50%;
  height: 90%;
  position: absolute;
  display: flex;
  flex-direction: column;
  color: white;
  padding-left: 4vw;
  margin-top: 2%;
  margin-left: 30%;
  text-transform: uppercase;
  background-color:rgba(0, 0, 0, 0.6);
  margin-bottom: 0.5vh;
  border-radius: 2vh;
  border: 1px solid white;

`

export const ArtTitle = styled.p`
  font-family: Hypercrack;
  font-size: 3.8vw;
  margin: 2vh 0px;

`

export const ArtInfo = styled.p`
  font-family: GangsterGrotesk_regular;
  font-size: 2vw;
  margin: 0.2vh 0px;
  text-transform: capitalize;
`

export const AddCart = styled.div`
  background: #30D5C8;
  font-size: 1.5vw;
  margin: 0.9vh 0;
  font-family: Gigranche_regular;
  border: none;
  padding: 7px 30px;
  width: fit-content;
  color: black;
  margin-top: 5vh;

  &:hover{
    color: white;
  }

`

export const Return = styled.div`
  background: #FFDF00;
  font-size: 1.7vw;
  margin: 0.9vh 0;
  font-family: Gigranche_regular;
  border: none;
  padding: 7px 30px;
  width: fit-content;
  color: black;

`

export const AlreadyAdded = styled.p`
  color: #30D5C8;
  font-family: GangsterGrotesk_bold;
  font-size: 2vw;
  margin: 0.9vh 0;

`