import emailjs from '@emailjs/browser';

const sendEmailConfimation = async (order_number, dataset, user, shippingFee, Price, totalPrice) => {
    const emailJsKey1 = process.env.REACT_APP_EMAILJS_KEY1;
    const emailJsKey2 = process.env.REACT_APP_EMAILJS_KEY2;
    const emailJsKey3 = process.env.REACT_APP_EMAILJS_KEY3;

    const info = "Name: " + user.name + "\n"
                + "Email: " + user.email + "\n"
                + "Address: " + user.address + "\n"
                + "Zip code: " + user.zip + "\n"
                + "City: " + user.city + "\n"
                + "Country: " + user.country + "\n"
                + "Phone: " + user.phone + "\n"
                + "ID: " + (user.idCard || "none") + "\n"
                + "Notes: " + (user.comment || "none") + "\n"

    let products = "<table style='padding:2px 10px; font-weight:normal'><tr><th>Product</th><th>Price</th><th>Quantity</th><th>Total</th></tr>";
    
    dataset.forEach(product => {
        products += "<tr> <th style='min-width:10vw'>" 
        + product.title 
        + "</th><th style='min-width:10vw'>" 
        + product.price 
        + "€ </th><th style='min-width:10vw'>"
        + product.quantity 
        + "</th><th style='min-width:10vw'>" 
        + product.price*product.quantity 
        + "€</th>"
    })

    products += "<tr><th>Shipping fee</th><th></th><th></th><th>" + shippingFee + "€</th></tr>"
    products += "<tr><th style='border-top:1px solid black'>Total price</th><th style='border-top:1px solid black'></th><th style='border-top:1px solid black'></th><th style='border-top:1px solid black'>" + totalPrice + "€</th></tr></table>"

    const emailData = {
        order_number,
        to_name: user.name,
        to_email: user.email,
        informations: info,
        items : products,
        }
  
      await emailjs .send(emailJsKey1,emailJsKey2, emailData, emailJsKey3)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
}

export default sendEmailConfimation