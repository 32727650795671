import {Categories, CategoriesContainer} from "../../styles/components/desktop/ShopCategories";
import {getAllSeries} from "../../services/artworks";
import {useEffect, useState} from "react";

export function ShopCategories( {onSelectCategory, selectedCategory} ) {
	const [categoriesList, setCategoriesList] = useState([]);

  const AllSeries = async () => {
    setCategoriesList(await getAllSeries());
  };

  useEffect(() => {
    AllSeries();
  }, []);


  return (
    <CategoriesContainer style={{marginTop:'5vh'}}>
      {categoriesList.map((data) => {
        const categoryName = data.Name;
        return (
          <Categories key={data.Id} onClick={() => onSelectCategory(data.Id)} className={data.Id === selectedCategory ? "isActive" : ""}>
            {categoryName}
          </Categories>
        );
      })}
    </CategoriesContainer>
  );
}