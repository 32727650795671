import { MurkhContact } from "../../components/desktop/MurkhContact"
import { Navbar } from "../../components/desktop/Navbar"
import { CursorTrail } from "../../components/desktop/CursorTrail"

export function Contact() {

    return (
        <>
            <Navbar />
            <CursorTrail currentPage={"about"} isLoaderDone={true} />
            <MurkhContact page={true} />
        </>
    )
}