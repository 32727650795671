import styled, { keyframes } from "styled-components";
import {Link} from "react-router-dom";

export const NavBarContainer = styled.div`
      font-family: Gigranche_semi;
      width: 110vw;
      height: 3.5vw;
      background: black;
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: rotate(-6.008deg);
      top: 3vw;
      right: -3vw;
      color: white;

  .endNav{
    margin-right: 28vw;
  }
`

export const HoverText = styled(Link)`
  display: flex;
  font-size: 2.8vw;
  width: 33vw;
  align-items: center;
  justify-content: center;
  cursor: none;
  text-decoration: none;
  color: white;

  &:hover {
    color:red;
    
    .stretch-letter {
      border-radius: 1vw;
      width: 7vw;
      
    }
  }
`;

export const StretchLetter = styled.div`
  display: inline-block;
  width: 1.1vw;
  height: 0.9vw;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s;
  border: 0.6vw solid #30D5C8;
`;

const marqueeAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

export const CartBarContainer = styled.div`
  font-family: Gigranche_semi;
  width: 38vw;
  height: 3.5vw;
  background: #FFB234;
  display: block;
  position: absolute;
  overflow: hidden;
  right: -5vw;
  top: 2%;
  transform: rotate(20deg);
`;

export const MovingContainer = styled.div`
  white-space: nowrap;
  width: max-content;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  animation: ${marqueeAnimation} 5s linear infinite;
  &:hover{
    animation-play-state: paused;
  }
`;

export const CartText = styled.p`
  display: inline-block;
  color: black;
  font-size: 2vw;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Logo = styled.img`
  width: 7vw;
  margin-right: 1.5vw;
  height: auto;
  margin-left: 10vw;
  border: 2px solid black;
  transition: animation 999s ease;

  &:hover{
    /* transform: rotate(12deg); */
    animation: ${spin} 2s linear forwards;
    /* transform: rotate(180deg); */
  
  }
`

export const LogoLink = styled(Link)`
  cursor: inherit;
  text-decoration: none;
`