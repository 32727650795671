import axios from "axios";

const apiLink = process.env.REACT_APP_BACKEND_SERVER_MURKHASYA;

export const getAllArtwork = async () => {
    try{
        const response = await axios.get(`${apiLink}/products/productsAvailable`);

        let result = [];

        response.data.forEach((element) => {
            let img = (element.artwork.images[0] !== undefined) ? window.location.origin + "/static/media/projects/murkhasya/" + element.artwork.id + "/" + element.artwork.images[0].fileName : "";

            result.push({
                "Id": element.id,
                "Picture": img,
                "Title": element.artwork.title,
                "SubTitle": element.artwork.description,
                "Size": element.artwork.size,
                "Type": "",
                "Art" : "paintings",
                "Price": element.price,
                "Quantity" : element.inventory,
                "Option": ""
            })});

        let filtered = result;

        return filtered;
    }
    catch(err){
        console.log(err);
    }
}

export const getAllSeries = async () => {
    try {
        const response = await axios.get(`${apiLink}/series/allSeries`);

        let result = [];

        response.data.forEach((element) => {
            result.push({
                "Id": element.id,
                "Name": element.name
            })});

        let filtered = result;

        return filtered;

    }catch(err){
        console.log(err);
    }
}

export const getAllArtworkFromSeries = async (idSerie) => {
    try{
        const response = await axios.get(`${apiLink}/products/seriesAvailable/${idSerie}`);

        let result = [];

        response.data.forEach((element) => {
            let img = (element.artwork.images[0] !== undefined) ? window.location.origin + "/static/media/projects/murkhasya/" + element.artwork.id + "/" + element.artwork.images[0].fileName : "";

            result.push({
                "Id": element.id,
                "Picture": img,
                "Title": element.artwork.title,
                "SubTitle": element.artwork.description,
                "Size": element.artwork.size,
                "Type": "",
                "Art" : "paintings",
                "Price": element.price,
                "Quantity" : element.inventory,
                "Option": ""
            })});

        let filtered = result;

        return filtered;
    }
    catch(err){
        console.log(err);
    }
}

export const getDetailsFromProductWithId = async (id) => {
    try {
        const response = await axios.get(`${apiLink}/products/${id}`);

        const img = response.data.artwork.images[0]?.fileName || "";

        const result = {
            "Id": response.data.id,
            "Picture": img
                ? window.location.origin + "/static/media/projects/murkhasya/" +
                response.data.artwork.id + "/" + img
                : "",
            "Title": response.data.artwork.title,
            "SubTitle": response.data.artwork.description,
            "Size": response.data.artwork.size,
            "Type": response.data.artwork.medium,
            "Art" : "paintings",
            "Price": response.data.price,
            "Quantity" : response.data.inventory,
            "Option": ""
        };

        return [result];

    }catch(err){
        console.log(err);
    }
}