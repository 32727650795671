import { useEffect, useState } from "react";
import { CursorImage, CursorTrailContainer } from "../../styles/components/desktop/CursorTrail";
import cursorIntro from "./../../assets/img/cursor-image-intro.png";
import cursorAbout from "./../../assets/img/cursor-image-about.png";
import cursorShop from "./../../assets/img/cursor-image-shop.png";
import cursorContact from "./../../assets/img/cursor-image-contact.png";

export function CursorTrail({ currentPage }) {
	const getCursorImage = () => {
		// Determine the cursor image based on the current page
		switch (currentPage) {
			case "intro":
				return cursorIntro;
			case "about":
				return cursorAbout;
			case "shop":
				return cursorShop;
			case "contact":
				return cursorContact;
			default:
				return cursorIntro;
		}
	};

	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	const [isFirstMove, setIsFirstMove] = useState(false);

	useEffect(() => {
		const handleMouseMove = (event) => {
			if (!isFirstMove) {
				setIsFirstMove(true);
			}
			setCursorPosition({ x: event.clientX, y: event.clientY });
		};

		window.addEventListener("mousemove", handleMouseMove);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, [isFirstMove]);

	return (
		<CursorTrailContainer>
			{isFirstMove && (
				<CursorImage
					src={getCursorImage()}
					alt="Cursor Trail"
					style={{
						transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
					}}
				/>
			)}
		</CursorTrailContainer>
	);
}
