import styled from "styled-components";

export const CursorTrailContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 9999;
`;

export const CursorImage = styled.img`
  position: absolute;
  width: 47px;
  height: 70px;
  pointer-events: none;
  transform: translate(30%, 40%);
`;